<template>
  <div>
    <div class="misc-wrapper">
      <div class="misc-inner p-sm-3">
        <div class="w-100 text-center mt-1">
          <b-img class="w-25 mb-3"
                 fluid
                 :src="imgUrl"
          />
          <h2 class="mb-2">
            {{ $t('outboxEmpty') }}
          </h2>
          <p class="mb-2">
            {{ $t('outboxEmptyDescription') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {BImg} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'

export default {
  name: "Empty",
  components: {
    BImg
  },
  props: {},
  directives: {
    Ripple,
  },
  data() {
    return {
      modalShow: false,
      emptyImg: require('@/assets/images/illustration/sent_box.svg'),
    }
  },
  computed: {
    imgUrl() {
      return this.emptyImg
    },
  },
}
</script>

<style scoped>

</style>